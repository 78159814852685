<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/facai.svg">
    <h2>恭喜发财，新春快乐</h2>
  </div>
</template>

<script>


export default {
  name: 'HomeView',
  components: {}
}
</script>
